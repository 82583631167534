import React, { useState, useEffect, useRef } from 'react';
import { PlayCircle, StopCircle, Download} from 'lucide-react';
import Metronome from './Metronome';

// Define a custom type for the oscillator reference
type OscillatorData = {
  oscillator: OscillatorNode;
  gainNode: GainNode;
};

interface RecordedNote {
  note: string;
  freq: number;
  timestamp: number;
  waveform?: OscillatorType;
  type: 'noteOn' | 'noteOff';
}

// Piano key mappings (keyboard key to frequency)
// https://en.wikipedia.org/wiki/Piano_key_frequencies
const keyMap = {
  'a': { note: 'C4', freq: 261.63 },
  'w': { note: 'C#4', freq: 277.18 },
  's': { note: 'D4', freq: 293.66 },
  'e': { note: 'D#4', freq: 311.13 },
  'd': { note: 'E4', freq: 329.63 },
  'f': { note: 'F4', freq: 349.23 },
  't': { note: 'F#4', freq: 369.99 },
  'g': { note: 'G4', freq: 392.00 },
  'y': { note: 'G#4', freq: 415.30 },
  'h': { note: 'A4', freq: 440.00 },
  'u': { note: 'A#4', freq: 466.16 },
  'j': { note: 'B4', freq: 493.88 },
  'k': { note: 'C5', freq: 523.25 },
  'b': { note: 'Kick', freq: 60 },  // Low frequency for kick
  'n': { note: 'Snare', freq: 200 }, // Mid frequency for snare
} as const;

type KeyMapKey = keyof typeof keyMap;

const VirtualPiano = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set());
  const [recordedNotes, setRecordedNotes] = useState<RecordedNote[]>([]);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.5);
  const [waveform, setWaveform] = useState<OscillatorType>('sine');
  const [isSustainOn, setIsSustainOn] = useState<boolean>(false);
  const recordingStartTime = useRef<number | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const oscillators = useRef<Record<string, OscillatorData>>({});
  const sustainedNotes = useRef<Set<string>>(new Set());
  const masterGainNode = useRef<GainNode | null>(null);
  const playbackTimeoutIds = useRef<NodeJS.Timeout[]>([]);


  useEffect(() => {
    const AudioContextClass = (window.AudioContext || (window as any).webkitAudioContext);
    audioContext.current = new AudioContextClass();
    masterGainNode.current = audioContext.current.createGain();
    masterGainNode.current.connect(audioContext.current.destination);
    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (masterGainNode.current && audioContext.current) {
      masterGainNode.current.gain.setValueAtTime(volume, audioContext.current.currentTime);
    }
  }, [volume]);

  const playNote = (key: string, customGain: number | null = null) => {
    if (isMuted || !audioContext.current) return;

    if (!(key in keyMap)) return;
    const typedKey = key as KeyMapKey;

    if (key === 'b' || key === 'n') {
      const oscillator = audioContext.current.createOscillator();
      const gainNode = audioContext.current.createGain();

      if (key === 'b') {  // Kick drum
        oscillator.type = 'sine';
        oscillator.frequency.setValueAtTime(150, audioContext.current.currentTime);
        oscillator.frequency.linearRampToValueAtTime(0.01, audioContext.current.currentTime + 0.15);
        gainNode.gain.setValueAtTime(1, audioContext.current.currentTime);
        gainNode.gain.linearRampToValueAtTime(0.01, audioContext.current.currentTime + 0.15);
      } else {  // Snare drum
        // noise for snare
        const bufferSize = audioContext.current.sampleRate * 0.1; // 100ms buffer
        const buffer = audioContext.current.createBuffer(1, bufferSize, audioContext.current.sampleRate);
        const output = buffer.getChannelData(0);
        for (let i = 0; i < bufferSize; i++) {
          output[i] = Math.random() * 2 - 1;
        }

        const noise = audioContext.current.createBufferSource();
        noise.buffer = buffer;

        // bandpass filter for more snare-like sound
        const filter = audioContext.current.createBiquadFilter();
        filter.type = 'bandpass';
        filter.frequency.value = 1000;
        filter.Q.value = 1;

        // Connects noise through filter to gain
        noise.connect(filter);
        filter.connect(gainNode);

        gainNode.gain.setValueAtTime(1.4, audioContext.current.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.current.currentTime + 0.1);

        noise.start();
        noise.stop(audioContext.current.currentTime + 0.1);
        
        gainNode.connect(masterGainNode.current!);
        return;
      }

      oscillator.connect(gainNode);
      gainNode.connect(masterGainNode.current!);
      oscillator.start();
      oscillator.stop(audioContext.current.currentTime + 0.15);
      return;
    }

    const oscillator = audioContext.current.createOscillator();
    const gainNode = audioContext.current.createGain();

    oscillator.type = waveform;
    oscillator.frequency.setValueAtTime(keyMap[typedKey].freq, audioContext.current.currentTime);

    const initialGain = customGain ?? 0.5;
    gainNode.gain.setValueAtTime(initialGain, audioContext.current.currentTime);
    gainNode.gain.setValueAtTime(initialGain, audioContext.current.currentTime + 0.1);

    if (!isSustainOn) {
      gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.current.currentTime + 1);
    }

    oscillator.connect(gainNode);
    if (masterGainNode.current) {
      gainNode.connect(masterGainNode.current);
    }
    oscillator.start();
    oscillators.current[key] = { oscillator, gainNode };

    if (isSustainOn) {
      sustainedNotes.current.add(key);
    }
  };

  const stopNote = (key: string) => {
    if (key === 'b' || key === 'n') return;

    if (oscillators.current[key] && (!isSustainOn || !sustainedNotes.current.has(key)) && audioContext.current) {
      const { oscillator, gainNode } = oscillators.current[key];
      gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.current.currentTime + 0.1);
      oscillator.stop(audioContext.current.currentTime + 0.1);
      delete oscillators.current[key];
      sustainedNotes.current.delete(key);
    }
  };

  const releaseSustainedNotes = () => {
    sustainedNotes.current.forEach((key: string) => {
      if (oscillators.current[key] && !activeKeys.has(key)) {
        stopNote(key);
      }
    });
    sustainedNotes.current.clear();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase();

    if (key === ' ') {
      event.preventDefault();
      if (!isSustainOn) {
        setIsSustainOn(true);
      }
      return;
    }

    if (key === 'tab') {
      event.preventDefault();
      toggleMute();
      return;
    }

    if (key in keyMap && !activeKeys.has(key)) {
      const typedKey = key as KeyMapKey;
      const newActiveKeys = new Set(activeKeys);
      newActiveKeys.add(key);
      setActiveKeys(newActiveKeys);
      playNote(key);

      if (isRecording) {
        const timestamp = Date.now() - (recordingStartTime.current ?? 0);
        setRecordedNotes(prev => [...prev, {
          note: keyMap[typedKey].note,
          freq: keyMap[typedKey].freq,
          timestamp,
          waveform,
          type: 'noteOn'
        }]);
      }
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase();

    if (key === ' ') {
      setIsSustainOn(false);
      releaseSustainedNotes();
      return;
    }

    if (key in keyMap) {
      const typedKey = key as KeyMapKey;
      const newActiveKeys = new Set(activeKeys);
      newActiveKeys.delete(key);
      setActiveKeys(newActiveKeys);
      stopNote(key);

      if (isRecording) {
        const timestamp = Date.now() - (recordingStartTime.current ?? 0);
        setRecordedNotes(prev => [...prev, {
          note: keyMap[typedKey].note,
          freq: keyMap[typedKey].freq,
          timestamp,
          type: 'noteOff'
        }]);
      }
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordedNotes([]);
    recordingStartTime.current = Date.now();
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const downloadRecording = async () => {
    if (!recordedNotes.length || !audioContext.current) return;

    // Create an offline audio context for rendering
    const offlineCtx = new OfflineAudioContext({
      numberOfChannels: 2,
      length: 44100 * (recordedNotes[recordedNotes.length - 1].timestamp / 1000 + 2), // +2 seconds buffer
      sampleRate: 44100
    });

    // Create a master gain node for the offline context
    const offlineMasterGain = offlineCtx.createGain();
    offlineMasterGain.connect(offlineCtx.destination);
    offlineMasterGain.gain.value = volume;

    // Play all notes in the offline context
    recordedNotes.forEach((note) => {
      if (note.type === 'noteOn') {
        const keyEntry = Object.entries(keyMap).find(([_, data]) => data.note === note.note);
        if (!keyEntry) return;

        const [key] = keyEntry;
        if (key === 'b' || key === 'n') {
          // Handle drum sounds similarly to playNote function
          // ... (implement drum handling if needed)
          return;
        }

        const oscillator = offlineCtx.createOscillator();
        const gainNode = offlineCtx.createGain();

        oscillator.type = note.waveform || 'sine';
        oscillator.frequency.setValueAtTime(note.freq, note.timestamp / 1000);

        gainNode.gain.setValueAtTime(0.5, note.timestamp / 1000);
        gainNode.gain.exponentialRampToValueAtTime(0.001, (note.timestamp / 1000) + 1);

        oscillator.connect(gainNode);
        gainNode.connect(offlineMasterGain);

        oscillator.start(note.timestamp / 1000);
        oscillator.stop(note.timestamp / 1000 + 1);
      }
    });

    try {
      // Render the audio
      const audioBuffer = await offlineCtx.startRendering();

      // Convert to WAV
      const wav = audioBufferToWav(audioBuffer);
      const blob = new Blob([wav], { type: 'audio/wav' });

      // Create download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'piano-recording.wav';
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error creating audio file:', error);
    }
  };

  // Add this helper function to convert AudioBuffer to WAV format
  function audioBufferToWav(buffer: AudioBuffer) {
    const numChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;
    
    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;
    
    const wav = new ArrayBuffer(44 + buffer.length * blockAlign);
    const view = new DataView(wav);
    
    // Write WAV header
    const writeString = (offset: number, string: string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };
    
    writeString(0, 'RIFF');
    view.setUint32(4, 36 + buffer.length * blockAlign, true);
    writeString(8, 'WAVE');
    writeString(12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * blockAlign, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitDepth, true);
    writeString(36, 'data');
    view.setUint32(40, buffer.length * blockAlign, true);
    
    // Write audio data
    const offset = 44;
    const channelData = new Float32Array(buffer.length);
    for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
      buffer.copyFromChannel(channelData, channel);
      for (let i = 0; i < channelData.length; i++) {
        const sample = Math.max(-1, Math.min(1, channelData[i]));
        const int16 = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
        view.setInt16(offset + (i * blockAlign) + (channel * bytesPerSample), int16, true);
      }
    }
    
    return new Uint8Array(wav);
  }

  const toggleMute = () => {
    setIsMuted(!isMuted);
    
    // Stop all currently playing notes
    if (audioContext.current) {
      // Stop all oscillators
      Object.keys(oscillators.current).forEach(key => {
        const { oscillator, gainNode } = oscillators.current[key];
        gainNode.gain.setValueAtTime(0, audioContext.current!.currentTime);
        oscillator.stop(audioContext.current!.currentTime + 0.1);
        delete oscillators.current[key];
      });
      
      // Clear active and sustained notes
      setActiveKeys(new Set());
      sustainedNotes.current.clear();
      setIsSustainOn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      playbackTimeoutIds.current.forEach(id => clearTimeout(id));
    };
  }, [activeKeys, isRecording, isMuted, isSustainOn, waveform]);

  const renderKey = (keyLabel: string, note: string, isSharp = false) => {
    const isActive = activeKeys.has(keyLabel);

    const startNote = (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault(); // Prevent default touch behavior
      handleKeyDown({ key: keyLabel } as KeyboardEvent);
    };

    const endNote = (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault(); // Prevent default touch behavior
      handleKeyUp({ key: keyLabel } as KeyboardEvent);
    };

    return (
      <div
        className={`
          ${isSharp 
            ? 'bg-black text-white h-[120px] w-[40px] -mx-[20px] z-10 relative' 
            : 'bg-white text-black h-[180px] w-[60px] relative z-0'}
          ${isActive 
            ? isSharp 
              ? 'bg-blue-600 shadow-[0_0_15px_5px_rgba(37,99,235,0.5)]' 
              : 'bg-blue-200 shadow-[0_0_15px_5px_rgba(59,130,246,0.5)]'
            : ''}
          border border-gray-300
          flex flex-col justify-end
          items-center
          pb-4
          cursor-pointer
          select-none
          touch-none
          transition-all duration-100 ease-in-out
          ${isActive ? 'after:absolute after:inset-[-15px] after:bg-blue-500/20 after:blur-[15px] after:-z-10 after:rounded-lg' : ''}
        `}
        onMouseDown={startNote}
        onMouseUp={endNote}
        onMouseLeave={() => activeKeys.has(keyLabel) && endNote}
        onTouchStart={startNote}
        onTouchEnd={endNote}
        onTouchCancel={endNote}
      >
        <span className="text-xs font-medium">{note}</span>
        <span className="text-[10px] mt-1 opacity-60">{keyLabel.toUpperCase()}</span>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
      <div className="mb-8 space-y-4">
        <div className="flex gap-4 flex-wrap">
          <button
            onClick={isRecording ? stopRecording : startRecording}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors disabled:opacity-50"
          >
            {isRecording ? (
              <>
                <StopCircle className="w-5 h-5" />
                Stop Recording
              </>
            ) : (
              <>
                <PlayCircle className="w-5 h-5" />
                Start Recording
              </>
            )}
          </button>

          {recordedNotes.length > 0 && (
            <button
              onClick={downloadRecording}
              className="flex items-center gap-2 px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition-colors"
            >
              <Download className="w-5 h-5" />
              Download Recording
            </button>
          )}
        </div>

        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <label className="text-sm font-medium">Waveform:</label>
            <select
              value={waveform}
              onChange={(e) => setWaveform(e.target.value as OscillatorType)}
              className="px-2 py-1 rounded border"
            >
              <option value="sine">Sine</option>
              <option value="square">Square</option>
              <option value="sawtooth">Sawtooth</option>
              <option value="triangle">Triangle</option>
            </select>
          </div>

          <div className="flex items-center gap-2">
            <label className="text-sm font-medium">Volume:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => setVolume(parseFloat(e.target.value))}
              className="w-32"
            />
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-center">
        <div className="relative flex justify-center">
          {Object.entries(keyMap).map(([key, { note }]) =>
            renderKey(key, note, note.includes('#'))
          )}
        </div>
        
        {/* Add sustain pedals side by side */}
        <div className="mt-8 flex flex-col items-center">
          <div className="flex gap-4"> {/* Added container for pedals */}
            <div className="flex flex-col items-center">
              <div 
                className={`
                  w-32 h-8 
                  border-2 border-gray-400 
                  rounded-md 
                  cursor-pointer 
                  transition-all duration-200
                  ${isSustainOn 
                    ? 'bg-gray-600 translate-y-1 shadow-inner' 
                    : 'bg-gray-300 shadow-lg hover:bg-gray-400'
                  }
                `}
                onClick={() => setIsSustainOn(!isSustainOn)}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setIsSustainOn(true);
                }}
                onMouseUp={() => setIsSustainOn(false)}
                onMouseLeave={() => setIsSustainOn(false)}
                onTouchStart={(e) => {
                  e.preventDefault();
                  setIsSustainOn(true);
                }}
                onTouchEnd={() => setIsSustainOn(false)}
              />
              <span className="mt-2 text-sm text-gray-600">
                Sustain (Space)
              </span>
            </div>

            <div className="flex flex-col items-center">
              <div 
                className={`
                  w-32 h-8 
                  border-2 border-gray-400 
                  rounded-md 
                  cursor-pointer 
                  transition-all duration-200
                  ${isMuted 
                    ? 'bg-gray-600 translate-y-1 shadow-inner' 
                    : 'bg-gray-300 shadow-lg hover:bg-gray-400'
                  }
                `}
                onClick={toggleMute}
              />
              <span className="mt-2 text-sm text-gray-600">
                End Sustain (Tab)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-gray-600 text-center">
        <p className="text-lg">Press the corresponding keys on your keyboard to play notes</p>
        <p className="text-m"><i>Hold spacebar to sustain notes</i></p>
      </div>

      <Metronome />
    </div>
  );
};

export default VirtualPiano;