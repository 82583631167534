import React, { useState, useRef, useEffect } from 'react';

const Metronome = () => {
  const [bpm, setBpm] = useState(60);
  const [isPlaying, setIsPlaying] = useState(false);
  const [tempo, setTempo] = useState("LARGO - LENTO - ADAGIO");
  const audioContext = useRef<AudioContext | null>(null);
  const intervalId = useRef<number | null>(null);

  useEffect(() => {
    audioContext.current = new AudioContext();
    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update tempo marking based on BPM
    if (bpm <= 76) setTempo("LARGO - LENTO - ADAGIO");
    else if (bpm <= 108) setTempo("ANDANTE - MODERATO");
    else if (bpm <= 168) setTempo("ALLEGRO");
    else setTempo("PRESTO - PRESTISSIMO");
  }, [bpm]);

  const playClick = () => {
    if (!audioContext.current) return;

    const oscillator = audioContext.current.createOscillator();
    const gainNode = audioContext.current.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.current.destination);

    oscillator.frequency.value = 440;
    gainNode.gain.setValueAtTime(0.3, audioContext.current.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.01, 
      audioContext.current.currentTime + 0.05
    );

    oscillator.start();
    oscillator.stop(audioContext.current.currentTime + 0.05);
  };

  const startStop = () => {
    if (isPlaying) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      setIsPlaying(false);
    } else {
      playClick();
      intervalId.current = window.setInterval(() => {
        playClick();
      }, (60 / bpm) * 1000);
      setIsPlaying(true);
    }
  };

  const handleTap = () => {
    // Implement tap tempo logic here
    // For now, just increment BPM as a placeholder
    setBpm(prev => Math.min(prev + 1, 208));
  };

  return (
    <div className="flex flex-col items-center bg-gray-900 p-6 rounded-lg max-w-md mx-auto mt-8">
      <div className="text-[80px] font-bold text-[#ff6b6b] mb-2">
        {bpm}
        <span className="text-2xl ml-2">BPM</span>
      </div>
      
      <div className="text-gray-300 text-sm mb-6">
        {tempo}
      </div>

      <div className="w-full flex items-center justify-center gap-4 mb-6">
        <button 
          className="w-10 h-10 rounded-full bg-gray-700 text-white flex items-center justify-center hover:bg-gray-600"
          onClick={() => setBpm(prev => Math.max(prev - 1, 40))}
        >
          -
        </button>
        
        <input
          type="range"
          min="40"
          max="208"
          value={bpm}
          onChange={(e) => setBpm(parseInt(e.target.value))}
          className="w-64 accent-[#ff6b6b]"
        />
        
        <button 
          className="w-10 h-10 rounded-full bg-gray-700 text-white flex items-center justify-center hover:bg-gray-600"
          onClick={() => setBpm(prev => Math.min(prev + 1, 208))}
        >
          +
        </button>
      </div>

      <div className="flex gap-4">
        <button
          className="px-8 py-4 bg-white text-gray-900 rounded-lg font-bold hover:bg-gray-100"
          onClick={handleTap}
        >
          TAP
        </button>
        
        <button
          className={`w-16 h-16 rounded-full flex items-center justify-center ${
            isPlaying ? 'bg-[#ff6b6b]' : 'bg-white'
          } hover:opacity-90`}
          onClick={startStop}
        >
          {isPlaying ? (
            <span className="w-4 h-4 bg-white rounded-sm" />
          ) : (
            <span className="w-0 h-0 border-t-8 border-b-8 border-l-12 border-transparent border-l-gray-900 ml-1" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Metronome; 